<template>
  <div class="row no-gutters compare-vehicle-nav px-2">
    <div class="col-12 text-center text-xl-left col-xl">
      <a href="#" @click.prevent="navigate('left')"><span class="fas fa-chevron-circle-left"></span></a>
      <a href="#" @click.prevent="navigate('right')"><span class="fas fa-chevron-circle-right"></span></a>
      <label>browse similar vehicles</label>
    </div>
    <div class="col-12 col-xl text-center text-xl-right">
        <label>choose a different vehicle</label>
        <a href="#" @click.prevent="chooseVehicle"><span class="fas fa-exchange-alt"></span></a>
    </div>
  </div>
</template>

<script>
export default {
    methods: {
        navigate(direction){
            this.$emit(`navigate:${direction}`)
        },
        chooseVehicle(){
            this.$emit("chooseVehicle");
        }
    }
};
</script>

<style lang="scss">
.compare-vehicle-nav {
  background-color: #eeeeee;
  min-height: 37px;
  padding-top: 8px;

  label {
    color: #797979;
    font-size: 0.775rem;
    padding-left: 6px;
    padding-right: 6px;

  }

  .fas {
    color: #3c68ca;
    top: 2px;
    position: relative;
    font-size: 1.1rem;
    padding-left: 2px;
    padding-right: 2px;
    font-weight: bold;
  }
}
</style>
